.hero {
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: url("/static/header.svg");
  background-attachment: fixed;
  color: white;
}
