@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css";
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: 'Maven Pro', sans-serif;
}

*,
::before,
::after {
  box-sizing: border-box
}

*::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 10px rgb(255, 255, 255);
  background-color: #002e4b !important;
  border-radius: 8px;
}

*::-webkit-scrollbar
{
  width: 8px;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 8px;
  background: #f97316;

}
